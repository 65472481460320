<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :is-full-page="fullPage"
                 :height="300"
                 :width="300"
                 :color="'rgba(91,75,217,0.78)'"
                 :loader="'dots'"
                 :opacity="1"
        ></loading>

        <a :href="'/'" ><img id="exitButton" :src="exitImg" alt="exit"></a>

        <div v-if="errorInRetrieving" class="row" style="margin-top: 9rem;">
            <img id="errorImage" :src="notFoundImage" alt="Data  not found" style="margin-left: -8px; max-width: 100%; margin-bottom: 15px;">
        </div>

        <div v-if="sensorReadings[0]">
        <div class="row-fluid px-3" style="color: #838383">
            <h3 class="my-2">{{this.translations['weather_station']}} "<span v-if="node[0]">{{node[0].name}}</span>"</h3>
            <h5 v-if="node[0]">
                {{ node[0].lat.toString().substr(0,2) }}° {{ node[0].lat.toString().substr(3,2)}}' N   {{ node[0].lng.toString().substr(0,2)}}° {{ node[0].lng.toString().substr(3,2)}}' W
                <span v-if="node"> | {{this.translations['latest_measurement']}} : <small v-if="sensorReadings[0]">{{ sensorReadings[0]['CREATED_AT'].substr(0, 16) }} </small></span>
            </h5>
        </div>
        <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>

        <div :key="refreshIteration">
            <!-- Gauges -->
            <div class="row mx-5">
<!--                <h2>Τελευταίες μετρήσεις</h2>-->
                <div class="col-sm-4" align="center">
                    <h3>{{this.translations['temperature']}} (°C)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="temp"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}} min : <b>{{ minMaxReadings.min_temp }}</b> °C | max : <b>{{ minMaxReadings.max_temp }}</b> °C</h5>
                </div>
                <div class="col-sm-4" align="center">
                    <h3>{{this.translations['humidity']}} (%)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="hum"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}} min : <b>{{ minMaxReadings.min_hum }}</b> % | max : <b>{{ minMaxReadings.max_hum }}</b> %</h5>
                </div>
                <div class="col-sm-4" align="center">
                    <h3>{{this.translations['uv_radiation']}}</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="uv"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}} min : <b>{{ minMaxReadings.min_uv }}</b> | max : <b>{{ minMaxReadings.max_uv }}</b> </h5>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm-4" align="center">
                    <h3>{{this.translations['wind_speed']}} (km/h)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="wind"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}}
                        min : <b>{{ minMaxReadings.min_wind_speed }}</b> km/h - <b>{{ minMaxReadings.min_wind_speed_beaufort }} Beaufort</b> |
                        max : <b>{{ minMaxReadings.max_wind_speed }}</b> km/h - <b>{{ minMaxReadings.max_wind_speed_beaufort }} Beaufort</b></h5>
                </div>
                <div class="col-sm-4" align="center">
                    <h3>{{this.translations['wind_direction']}}</h3>
                    <compass v-if="this.sensorReadings[0]" :direction="this.sensorReadings[0]['wind_direction']"></compass>
                </div>
                <div class="col-sm-4" align="center">
                    <h3>{{this.translations['gust_speed']}} (km/h)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="gust"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}}
                        min : <b>{{ minMaxReadings.min_gust_speed }}</b> km/h - <b>{{ minMaxReadings.min_gust_speed_beaufort }} Beaufort</b> |
                        max : <b>{{ minMaxReadings.max_gust_speed }}</b> km/h - <b>{{ minMaxReadings.max_gust_speed_beaufort }} Beaufort</b></h5>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h3>{{this.translations['light_intensity']}} (W/m<sup>2</sup>)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="light"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}} min : <b>{{ minMaxReadings.min_light }}</b> W/m<sup>2</sup> | max : <b>{{ minMaxReadings.max_light  }}</b>  W/m<sup>2</sup></h5>
                </div>
                <div class="col-sm" align="center">
                    <h3>{{this.range==='24h'?this.translations['rainfall_24h']:this.translations['rainfall_7d']}} (mm)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="precip"></gauge>
                </div>
                <div class="col-sm" align="center">
                    <h3>{{this.translations['absolute_pressure']}} (hPa)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="pressure"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}} min : <b>{{ minMaxReadings.min_pressure }}</b> hPa | max : <b>{{ minMaxReadings.max_pressure }}</b>  hPa</h5>
                </div>
            </div>
            <div  v-if="this.range==='24h'" class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div  v-if="this.range==='24h'" class="row mx-5">
                <div class="col-sm-4" align="center">
                    <h3>{{this.translations['dew_point']}} (°C)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="dew_point"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}} min : <b>{{ minMaxReadings.min_dew_point }}</b> °C | max : <b>{{ minMaxReadings.max_dew_point }}</b> °C</h5>
                </div>
                <div class="col-sm-4" align="center">
                    <h3>{{this.translations['wind_chill']}} (°C)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="wind_chill"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}} min : <b>{{ minMaxReadings.min_wind_chill }}</b> °C | max : <b>{{ minMaxReadings.max_wind_chill }}</b> °C</h5>
                </div>
                <div class="col-sm-4" align="center">
                    <h3>{{this.translations['heat_index']}} (°C)</h3>
                    <gauge v-if="sensorReadings[0]" :sensorReadings="this.sensorReadings" type="heat_index"></gauge>
                    <h5 class="minMaxText" v-if="minMaxReadings">{{this.range}} min : <b>{{ minMaxReadings.min_heat_index }}</b> °C| max : <b>{{ minMaxReadings.max_heat_index }}</b> °C</h5>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>

<!--            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>-->


            <!-- Charts -->
            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h3>{{this.range==='24h'?this.translations['temperature_24h_graph']:this.translations['temperature_7d_graph']}} (°C)</h3>
                    <chart v-if="chartReadings[0]" type="temperature" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>{{this.range==='24h'?this.translations['humidity_24h_graph']:this.translations['humidity_7d_graph']}} (%)</h3>
                    <chart v-if="chartReadings[0]" type="humidity" :chartReadings="this.chartReadings"></chart>
                </div>

            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h3>{{this.range==='24h'?this.translations['wind_speed_24h_graph']:this.translations['wind_speed_7d_graph']}} (km/h)</h3>
                    <chart v-if="chartReadings[0]" type="wind_speed" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>{{this.range==='24h'?this.translations['gust_speed_24h_graph']:this.translations['gust_speed_7d_graph']}} (km/h)</h3>
                    <chart v-if="chartReadings[0]" type="gust_speed" :chartReadings="this.chartReadings"></chart>
                </div>
            </div>

            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5">
                <div class="col-sm" align="center">
                    <h3>{{this.range==='24h'?this.translations['light_intensity_speed_24h']:this.translations['light_intensity_speed_7d']}} (W/m<sup>2</sup>)</h3>
                    <chart v-if="sensorReadings[0]" type="light_intensity" :chartReadings="this.sensorReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>{{this.range==='24h'?this.translations['rainfall_24h_graph']:this.translations['rainfall_7d_graph']}} (mm)</h3>
                    <chart v-if="chartReadings[0]" type="rainfall" :chartReadings="this.chartReadings"></chart>
                </div>
            </div>

            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5 mb-5">
                <div class="col-sm" align="center">
                    <h3>{{this.range==='24h'?this.translations['uv_radiation_24h_graph']:this.translations['uv_radiation_7d_graph']}} (UV index)</h3>
                    <chart v-if="chartReadings[0]" type="uv_index" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>{{this.range==='24h'?this.translations['absolute_pressure_24h_graph']:this.translations['absolute_pressure_7d_graph']}} (hPa)</h3>
                    <chart v-if="chartReadings[0]" type="pressure" :chartReadings="this.chartReadings"></chart>
                </div>
            </div>
            <div class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div class="row mx-5 mb-5">
                <div class="col-sm" align="center">
                    <h3>{{ this.range==='24h'?this.translations['wind_direction_24h_graph']:this.translations['wind_direction_7d_graph']}}</h3>
                    <chart v-if="chartReadings[0]" type="wind_direction" :chartReadings="this.chartReadings"></chart>
                </div>
                <div v-if="this.range==='24h'" class="col-sm" align="center">
                    <h3>{{ this.range==='24h'?this.translations['dew_point_24h_graph']:this.translations['dew_point_7d_graph']}} (°C)</h3>
                    <chart v-if="chartReadings[0]" type="dew_point" :chartReadings="this.chartReadings"></chart>
                </div>
                <div v-else class="col-sm" align="center"></div>
            </div>

            <div v-if="this.range==='24h'" class="clearfix" style="border-bottom:1px solid #e3e3e3; margin-bottom:20px;"></div>
            <div v-if="this.range==='24h'" class="row mx-5 mb-5">
                <div class="col-sm" align="center">
                    <h3>{{ this.range==='24h'?this.translations['heat_index_24h_graph']:this.translations['heat_index_7d_graph']}} (°C)</h3>
                    <chart v-if="chartReadings[0]" type="heat_index" :chartReadings="this.chartReadings"></chart>
                </div>
                <div class="col-sm" align="center">
                    <h3>{{ this.range==='24h'?this.translations['wind_chill_24h_graph']:this.translations['wind_chill_7d_graph']}} (°C)</h3>
                    <chart v-if="chartReadings[0]" type="wind_chill" :chartReadings="this.chartReadings"></chart>
                </div>
            </div>
        </div>

        </div>


    </div>
</template>
<script>
import exit from '../../../public/images/close.png'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import notFoundIm from "../../../public/images/satellite.png";

export default {
    props : [ 'imei', 'range' ],
    components: {
        Loading
    },
    data() {
        return {
            translations:[],
            sensorReadings: [],
            chartReadings: [],
            minMaxReadings: [],
            node: [],
            exitImg: exit,
            isLoading: false,
            fullPage: true,
            notFoundImage: notFoundIm,
            errorInRetrieving: false,
            timer: "",
            refreshIteration: 0
        }
    },
    created() {
        this.translations = window.translations;
        this.fetchData();
        this.timer = setInterval(this.fetchData, 300000);
    },
    beforeDestroy() {
        this.cancelAutoUpdate();
    },
    methods : {
        fetchData() {
            this.isLoading = true;
            this.axios
                .get('/sensor-data', {
                    params: {
                        imei: this.imei,
                        offset: new Date().getTimezoneOffset(),
                        range: this.range
                    }
                })
                .then(response => {
                    if (response.data === 'error' ) {
                        console.log('err');
                        this.isLoading = false;
                        this.errorInRetrieving = true
                        return;
                    }

                    this.chartReadings = response.data;

                    this.sensorReadings = response.data;
                    // this.sensorReadings[0]['uv_power'] = this.convertUV(this.sensorReadings[0]['uv_power']);
                    // this.sensorReadings[0]['light_intensity'] = this.convertLux(this.sensorReadings[0]['light_intensity']);
                    for (let i = 0 ; i < this.sensorReadings.length; i++) {
                        this.sensorReadings[i]['light_intensity'] = this.convertLux(this.sensorReadings[i]['light_intensity']);
                        // this.sensorReadings[i]['uv_power'] = this.convertUV(this.sensorReadings[i]['uv_power']);
                    }


                    this.minMaxReadings['max_temp']        = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.temperature; }));
                    this.minMaxReadings['max_hum']         = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.humidity}));
                    this.minMaxReadings['max_wind_speed']  = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.wind_speed; }));
                    this.minMaxReadings['max_wind_speed_beaufort']  = this.convertSpeedToBeaufort(Math.max.apply(Math, this.chartReadings.map(function(o) { return o.wind_speed; })));
                    this.minMaxReadings['max_gust_speed']  = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.gust_speed; }));
                    this.minMaxReadings['max_gust_speed_beaufort']  = this.convertSpeedToBeaufort(Math.max.apply(Math, this.chartReadings.map(function(o) { return o.gust_speed; })));
                    this.minMaxReadings['max_uv']          = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.uv_index; }));
                    this.minMaxReadings['max_pressure']    = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.pressure; }));
                    this.minMaxReadings['max_light']       = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.light_intensity; }));
                    this.minMaxReadings['max_dew_point']       = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.dew_point; }));
                    this.minMaxReadings['max_wind_chill']       = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.wind_chill; }));
                    this.minMaxReadings['max_heat_index']       = Math.max.apply(Math, this.chartReadings.map(function(o) { return o.heat_index; }));

                    this.minMaxReadings['min_temp']        = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.temperature; }));
                    this.minMaxReadings['min_hum']         = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.humidity; }));
                    this.minMaxReadings['min_wind_speed']  = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.wind_speed; }));
                    this.minMaxReadings['min_wind_speed_beaufort']  = this.convertSpeedToBeaufort(Math.min.apply(Math, this.chartReadings.map(function(o) { return o.wind_speed; })));
                    this.minMaxReadings['min_gust_speed']  = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.gust_speed; }));
                    this.minMaxReadings['min_gust_speed_beaufort']  = this.convertSpeedToBeaufort(Math.min.apply(Math, this.chartReadings.map(function(o) { return o.gust_speed; })));
                    this.minMaxReadings['min_uv']          = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.uv_index; }));
                    this.minMaxReadings['min_pressure']    = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.pressure; }));
                    this.minMaxReadings['min_light']       = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.light_intensity; }));
                    this.minMaxReadings['min_dew_point']       = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.dew_point; }));
                    this.minMaxReadings['min_wind_chill']       = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.wind_chill; }));
                    this.minMaxReadings['min_heat_index']       = Math.min.apply(Math, this.chartReadings.map(function(o) { return o.heat_index; }));

                    // console.log(this.chartReadings);
                    // console.log(this.sensorReadings);
                    // console.log(this.minMaxReadings);
                    this.refreshIteration += 1;
                    console.log(this.refreshIteration);

                    return this.axios
                        .get('/node-basic-info', {
                        params: {
                            imei: this.imei
                        }
                    })
                })
                .then(response => {
                    this.node = response.data
                    // console.log(this.node);
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                })
        },
        cancelAutoUpdate() {
            clearInterval(this.timer);
        },
        convertSpeedToBeaufort(wind_speed) {
            let beaufort = 0;

            if (wind_speed >= 1.1 && wind_speed < 5.6)
                beaufort = 1;
            else if (wind_speed >= 5.6 && wind_speed < 12)
                beaufort = 2;
            else if (wind_speed >= 12 && wind_speed < 20)
                beaufort = 3;
            else if (wind_speed >= 20 && wind_speed < 29)
                beaufort = 4;
            else if (wind_speed >= 29 && wind_speed < 39)
                beaufort = 5;
            else if (wind_speed >= 39 && wind_speed < 50)
                beaufort = 6;
            else if (wind_speed >= 50 && wind_speed < 62)
                beaufort = 7;
            else if (wind_speed >= 62 && wind_speed < 75)
                beaufort = 8;
            else if (wind_speed >= 75 && wind_speed < 89)
                beaufort = 9;
            else if (wind_speed >= 89 && wind_speed < 103)
                beaufort = 10;
            else if (wind_speed >= 103 && wind_speed < 118)
                beaufort = 11;
            else if (wind_speed >= 118)
                beaufort = 12;

            return beaufort;
        },
        convertWindDirection(value) {
            return Math.round((value % 360) / 22.5) + 1;
        },
        compass(value) {
            let compass = [
                this.translations['-'],
                this.translations['N'],
                this.translations['NNE'],
                this.translations['NE'],
                this.translations['ENE'],
                this.translations['E'],
                this.translations['ESE'],
                this.translations['SE'],
                this.translations['SSE'],
                this.translations['S'],
                this.translations['SSW'],
                this.translations['SW'],
                this.translations['WSW'],
                this.translations['W'],
                this.translations['WNW'],
                this.translations['NW'],
                this.translations['NNW'],
                this.translations['N']
            ];

            return compass[value];
        },
        convertLux(value) {
            return Math.round(value * 0.0079, 2);
        },
    }
}
</script>

<style scoped >
#exitButton {
    position: fixed;
    z-index: 100;
    width: 30px;
    right: 50px;
    filter: opacity(60%);
}
#errorImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -15%);
    margin-top: 1rem;
}
@media all and (max-width: 800px) {
    .row {
        margin-left : 0.5rem !important;
        margin-right : 0.5rem !important;
    }
    .minMaxText {
        margin-bottom: 45px !important;
    }
    #exitButton {
        position: fixed;
        z-index: 100;
        width: 30px;
        right: 25px;
        filter: opacity(70%);
    }
}

</style>
